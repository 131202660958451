import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Countries } from 'countries-states-cities-service';
import { sellerLogin } from '../Pages/ApiEndpoint';

function Login() {
	const navigation = useNavigate('');
	const countries = Countries?.getCountries();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const handleSubmit = async (values) => {
		if (values?.email?.value?.length === 0) {
			toast.error('Please Enter a email');
		} else if (values?.password?.value?.length === 0) {
			toast.error('Please Enter a password');
		} else {
			const params = {
				yourEmail: username,
				createPassword: password,
			};

			// print form data

			console.log('params', params);

			const data = await fetch(`${sellerLogin}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(params),
			});
			const response = await data.json();

			// store the token in local storage

			if (data.ok) {
				console.log('response', response);
				localStorage.setItem('token', response?.token);
				localStorage.setItem('seller-user', JSON.stringify(response));
				localStorage.setItem('sellerId', response?.sellerID);
				localStorage.setItem('qcid', response?.qualityControllerID);
				toast.success('Login Successful');
				setPassword('');
				setUsername('');
				navigation('/dashboard');
			} else {
				console.log('responseaksjdhakjsdhladkjlakdj');

				toast.error('Invalid Credentials');
			}
		}
	};
	return (
		<>
			<div className='container'>
				<ToastContainer />
				<div className='row'>
					<div className='col-md-6'>
						<div className='login3'>
							<img
								src={require('../../assets/images/jack.jpeg')}
								alt=''
								style={{
									width: '100%',
									height: '100%',
									width: 'auto',
									height: 'auto',
									borderTopLeftRadius: '10px',
									borderBottomLeftRadius: '10px',
								}}
							/>
						</div>
					</div>
					<div className='col-md-6'>
						<section className='login2'>
							<div className='login'>
								<h5 style={{ marginBottom: '20px' }}>Login</h5>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit(e.target);
									}}
									className='form'
								>
									<label style={{ fontSize: '12px' }}>Email</label>
									<br />
									<input
										type='email'
										name='yourEmail'
										onChange={(e) => setUsername(e.target.value)}
										class='form-control no-focus'
										style={{
											width: '95%',
											marginBottom: '10px',
											backgroundColor: '#fff',
										}}
										placeholder='Enter Your Email'
									/>

									<label style={{ fontSize: '12px' }}>Password</label>
									<br />
									<input
										type='password'
										name='createPassword'
										onChange={(e) => setPassword(e.target.value)}
										class='form-control no-focus'
										style={{
											width: '95%',
											marginBottom: '30px',
											backgroundColor: '#fff',
										}}
										placeholder='Enter your password'
									/>

									<button
										type='submit'
										class='btn'
										style={{
											fontSize: '13px',
											width: '95%',
											backgroundColor: '#ffd700',
											borderColor: '#ffd700',
											marginTop: '-20px',
										}}
									>
										Login
									</button>
								</form>
							</div>
						</section>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
